import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import useKeyPress from "../../hooks/useKeyPress";
import R from "../../res";
import formatPhoneNumber from "../../util/formatPhoneNumber";
import isAnyEmpty from "../../util/isAnyEmpty";
import sendMail from "../../util/sendMail";
import validateEmail from "../../util/validateEmail";
import { ease } from "./constants";
import classes from "./index.module.scss";

const ContactPage = () => {
  const navigate = useNavigate();
  const [fullname, setFullname] = React.useState("");
  const [selectedServicesRequired, setSelectedServicesRequired] =
    React.useState<Array<string>>([]);
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [budget, setBudget] = React.useState("");
  const [projectBrief, setProjectBrief] = React.useState("");
  const [howDidYouHear, setHowDidYouHear] = React.useState("");
  const [showServicesRequiredDropdown, setShowServicesRequiredDropdown] =
    React.useState(false);
  const [showHowDidYouHearDropdown, setShowHowDidYouHearDropdown] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [showToastPopup, setShowToastPopup] = React.useState(false);
  const [wasMailSentSuccessfully, setWasMailSentSuccessfully] =
    React.useState(false);

  // const [toastInfo, setToastInfo] = React.useState({
  //   title: "",
  //   description: "",
  // });

  const [showBudgetOptionsDropdown, setShowBudgetOptionsDropdown] =
    React.useState(false);

  const escapeKeyPressed = useKeyPress("Escape");

  React.useEffect(() => {
    if (escapeKeyPressed) {
      navigate(-1);
    }
  }, [escapeKeyPressed]);

  const options = React.useMemo(
    () => [
      "Search Engine",
      "Brochure",
      "Co-Worker",
      "Friend/Family",
      "Social Media",
    ],
    []
  );

  const services = React.useMemo(
    () => [
      "App Development",
      "Web Design",
      "Graphic Design",
      "Media Design",
      "Branding",
      "Other",
    ],
    []
  );

  const budgetOptions = React.useMemo(
    () => ["Under $10,000", "$50,000", "$100,000+"],
    []
  );

  const sendForm = async () => {
    setIsLoading(true);
    const isSuccessful = await sendMail(
      fullname,
      selectedServicesRequired,
      howDidYouHear,
      email,
      phone,
      budget,
      projectBrief
    );
    setWasMailSentSuccessfully(isSuccessful);
    setShowToastPopup(true);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (showToastPopup) {
      setTimeout(() => {
        setShowToastPopup(false);
      }, 2000);
    }
  }, [showToastPopup]);

  const canProceed = React.useMemo(() => {
    if (emailError.trim().length > 0) {
      return false;
    }
    if (selectedServicesRequired.length === 0) return false;
    return !isAnyEmpty([
      email,
      phone,
      projectBrief,
      budget,
      fullname,
      howDidYouHear,
    ]);
  }, [
    email,
    emailError,
    phone,
    projectBrief,
    budget,
    fullname,
    howDidYouHear,
    selectedServicesRequired,
  ]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
          scale: 3,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            duration: 0.7,
            //   delay: 0.3,
          },
        }}
        exit={{
          opacity: 0,
          scale: 3,
          transition: {
            duration: 0.7,
            opacity: {
              duration: 0.3,
            },
          },
        }}
        className={classes["contact-page"]}
        onClick={() => {
          setShowServicesRequiredDropdown(false);
          setShowHowDidYouHearDropdown(false);
          setShowBudgetOptionsDropdown(false);
        }}
      >
        <div className={classes["container"]}>
          <AnimatePresence>
            {showToastPopup && (
              <motion.div
                initial={{
                  translateX: "200%",
                }}
                animate={{
                  translateX: "0%",
                  transition: {
                    ease: ease,
                    duration: 0.5,
                  },
                }}
                exit={{
                  translateX: "200%",
                  transition: {
                    ease: ease,
                    duration: 0.5,
                  },
                }}
                className={classes["toast"]}
              >
                <div
                  className={classes["strip"]}
                  style={{
                    backgroundColor: wasMailSentSuccessfully ? "green" : "red",
                  }}
                />
                <p className={classes["title"]}>
                  {wasMailSentSuccessfully ? "Success" : "Error"}
                </p>
                <p className={classes["description"]}>
                  {wasMailSentSuccessfully
                    ? "Your form was successfully submitted"
                    : "There was an issue submitting your form"}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
          <img
            src={R.images.default.modalBackground}
            alt="background"
            className={classes["background"]}
          />
          <button
            className={classes["back-button"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={R.images.default.modalBackIcon} alt="back-button" />
          </button>
          <div className={classes["content"]}>
            <p className={classes["main-title"]}>Request A Quote</p>
            <p className={classes["main-label"]}>
              Every project can be completely different. To get an accurate
              price, please request a quote below.
            </p>
            <div className={classes["text-input-wrapper"]}>
              <p className={classes["label"]}>name</p>
              <div className={classes["text-input-container"]}>
                <input
                  type="text"
                  placeholder="full name"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </div>
            </div>
            <div className={classes["text-input-wrapper"]}>
              <p className={classes["label"]}>services required</p>
              <div
                className={classes["text-input-container"]}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setShowServicesRequiredDropdown((prevValue) => !prevValue);
                  setShowHowDidYouHearDropdown(false);
                  setShowBudgetOptionsDropdown(false);
                  e.stopPropagation();
                }}
              >
                {selectedServicesRequired.length > 0 ? (
                  <div className={classes["options"]}>
                    <AnimatePresence mode="wait">
                      {selectedServicesRequired.map((option, index) => (
                        <motion.div
                          initial={{
                            scale: 0,
                          }}
                          animate={{
                            scale: 1,
                            transition: {
                              ease,
                              duration: 0.3,
                            },
                          }}
                          exit={{
                            scale: 0,
                            transition: {
                              ease,
                              duration: 0.3,
                              // type: "spring",
                              // stiffness: 90,
                            },
                          }}
                          key={index}
                          className={classes["option"]}
                        >
                          <p>{option}</p>
                          <button
                            className={classes["remove-button"]}
                            onClick={(e) => {
                              const list = [...selectedServicesRequired];
                              setSelectedServicesRequired(
                                list.filter((value) => value !== option)
                              );
                              e.stopPropagation();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#fff"
                              width={12}
                              height={12}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </motion.div>
                      ))}
                    </AnimatePresence>
                  </div>
                ) : (
                  <p className={classes["placeholder"]}>choose services</p>
                )}
                <button
                  className={classes["icon-button"]}
                  onClick={(e) => {
                    setShowServicesRequiredDropdown((prevValue) => !prevValue);
                    setShowHowDidYouHearDropdown(false);
                    setShowBudgetOptionsDropdown(false);
                    e.stopPropagation();
                  }}
                >
                  <img src={R.images.default.icChevron} alt="chevron" />
                </button>
                <AnimatePresence>
                  {showServicesRequiredDropdown && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      className={classes["dropdown"]}
                      onClick={(e) => {
                        // setShowServicesRequiredDropdown(
                        //   (prevValue) => !prevValue
                        // );
                        e.stopPropagation();
                      }}
                    >
                      {services.map((option, index) => {
                        const isSelected =
                          selectedServicesRequired.includes(option);
                        return (
                          <button
                            key={index}
                            className={classes["item"]}
                            onClick={() => {
                              if (isSelected) {
                                const list = [...selectedServicesRequired];
                                setSelectedServicesRequired(
                                  list.filter((value) => value !== option)
                                );
                              } else {
                                setSelectedServicesRequired((prevValues) => [
                                  ...prevValues,
                                  option,
                                ]);
                              }
                              setShowHowDidYouHearDropdown(false);
                            }}
                          >
                            <p style={{ fontWeight: isSelected ? 700 : 400 }}>
                              {option}
                            </p>
                          </button>
                        );
                      })}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className={classes["text-input-wrapper"]}>
              <p className={classes["label"]}>
                How did you hear about en2 tech?
              </p>
              <div
                className={classes["text-input-container"]}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setShowHowDidYouHearDropdown((prevValue) => !prevValue);
                  setShowServicesRequiredDropdown(false);
                  setShowBudgetOptionsDropdown(false);
                  e.stopPropagation();
                }}
              >
                <input
                  type="text"
                  placeholder="Select option"
                  value={howDidYouHear}
                  disabled
                  style={{ cursor: "pointer" }}
                />
                <button
                  className={classes["icon-button"]}
                  onClick={(e) => {
                    setShowHowDidYouHearDropdown((prevValue) => !prevValue);
                    setShowServicesRequiredDropdown(false);
                    setShowBudgetOptionsDropdown(false);
                    e.stopPropagation();
                  }}
                >
                  <img src={R.images.default.icChevron} alt="chevron" />
                </button>
                <AnimatePresence>
                  {showHowDidYouHearDropdown && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      className={classes["dropdown"]}
                      onClick={(e) => {
                        // setShowServicesRequiredDropdown(
                        //   (prevValue) => !prevValue
                        // );
                        e.stopPropagation();
                      }}
                    >
                      {options.map((option, index) => (
                        <button
                          key={index}
                          className={classes["item"]}
                          onClick={() => {
                            setHowDidYouHear(option);
                            setShowHowDidYouHearDropdown(false);
                          }}
                        >
                          <p>{option}</p>
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className={classes["row"]}>
              <div className={classes["col"]}>
                <div className={classes["text-input-wrapper"]}>
                  <p className={classes["label"]}>email</p>
                  <div
                    className={classes["text-input-container"]}
                    style={{
                      borderColor:
                        emailError.length > 0 ? "#ED0400" : undefined,
                    }}
                  >
                    <input
                      type="text"
                      placeholder="your email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);

                        if (emailError.length > 0) {
                          if (validateEmail(e.target.value)) {
                            setEmailError("");
                          } else {
                            setEmailError("Please enter valid email address");
                          }
                        }

                        if (e.target.value.length === 0) {
                          setEmailError("");
                        }
                      }}
                      onBlur={() => {
                        if (email.trim().length === 0) {
                          return;
                        }
                        if (!email || !validateEmail(email)) {
                          setEmailError("Please enter valid email address");
                          return;
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes["col"]}>
                <div className={classes["text-input-wrapper"]}>
                  <p className={classes["label"]}>phone</p>
                  <div className={classes["text-input-container"]}>
                    <input
                      type="text"
                      placeholder="your contact phone"
                      value={phone}
                      onChange={(e) =>
                        setPhone(formatPhoneNumber(e.target.value))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes["text-input-wrapper"]}>
              <p className={classes["label"]}>budget</p>
              <div
                className={classes["text-input-container"]}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  setShowBudgetOptionsDropdown((prevValue) => !prevValue);
                  setShowServicesRequiredDropdown(false);
                  setShowHowDidYouHearDropdown(false);
                  e.stopPropagation();
                }}
              >
                <input
                  type="text"
                  placeholder="your budget"
                  value={budget}
                  // onChange={(e) => setBudget(e.target.value)}
                  disabled
                  style={{ cursor: "pointer" }}
                />
                <button
                  className={classes["icon-button"]}
                  onClick={(e) => {
                    setShowBudgetOptionsDropdown((prevValue) => !prevValue);
                    setShowServicesRequiredDropdown(false);
                    setShowHowDidYouHearDropdown(false);
                    e.stopPropagation();
                  }}
                >
                  <img src={R.images.default.icChevron} alt="chevron" />
                </button>
                <AnimatePresence>
                  {showBudgetOptionsDropdown && (
                    <motion.div
                      initial={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      animate={{
                        opacity: 1,
                        translateY: 0,
                        transition: {
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        translateY: -20,
                      }}
                      className={classes["dropdown"]}
                      onClick={(e) => {
                        // setShowServicesRequiredDropdown(
                        //   (prevValue) => !prevValue
                        // );
                        e.stopPropagation();
                      }}
                    >
                      {budgetOptions.map((option, index) => (
                        <button
                          key={index}
                          className={classes["item"]}
                          onClick={() => {
                            setBudget(option);
                            setShowBudgetOptionsDropdown(false);
                          }}
                        >
                          <p>{option}</p>
                        </button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
            <div className={classes["text-input-wrapper"]}>
              <p className={classes["label"]}>project brief</p>
              <div className={classes["textarea-container"]}>
                <textarea
                  placeholder="Type a few words about your project"
                  value={projectBrief}
                  onChange={(e) => setProjectBrief(e.target.value)}
                />
              </div>
            </div>
            <button
              className={classes["button"]}
              onClick={sendForm}
              disabled={isLoading || !canProceed}
            >
              {isLoading ? <Loader color="#2f3a95" /> : <p>send</p>}
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default ContactPage;
