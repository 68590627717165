const formatPhoneNumber = (value: string, clear = false): string => {
	var cleaned = ('' + value).replace(/\D/g, '');
	if (clear) {
		return cleaned;
	}

	var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? '+1 ' : '';
		return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
	}

	return cleaned;
};

export default formatPhoneNumber;
