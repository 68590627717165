import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import useKeyPress from "../../hooks/useKeyPress";
import R from "../../res";
import classes from "./index.module.scss";

const TermsOfServicePage = () => {
  const navigate = useNavigate();
  const escapeKeyPressed = useKeyPress("Escape");

  React.useEffect(() => {
    if (escapeKeyPressed) {
      navigate(-1);
    }
  }, [escapeKeyPressed]);

  return (
    <>
      <motion.div
        initial={{
          opacity: 0,
          translateY: 30,
          // scale: 3,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          // scale: 1,
          transition: {
            duration: 0.4,
          },
        }}
        exit={{
          opacity: 0,
          translateY: 30,
          // scale: 3,
          transition: {
            duration: 0.4,
          },
        }}
        className={classes["container"]}
      >
        <div className={classes["content-wrapper"]}>
          <img
            src={R.images.default.modalBackground}
            alt="background"
            className={classes["background"]}
          />
          <button
            className={classes["back-button"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={R.images.default.modalBackIcon} alt="back-button" />
          </button>
          <div className={classes["content"]}>
            <p className={classes["main-title"]}>Terms of Service</p>
            <p className={classes["main-label"]}>
              Last Updated: <span>January 26th, 2023</span>
            </p>

            <p className={`${classes["label"]}`}>
              This privacy notice discloses the privacy practices for
              (https://en2.tech). This privacy notice applies solely to
              information collected by this website. It will notify you of the
              following: What personally identifiable information is collected
              from you through the website, how it is used and with whom it may
              be shared. What choices are available to you regarding the use of
              your data. The security procedures in place to protect the misuse
              of your information. How you can correct any inaccuracies in the
              information.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>
              Information Collection, Use, and Sharing
            </p>
            <p className={classes["label"]}>
              We are the sole owners of the information collected on this site.
              We only have access to/collect information that you voluntarily
              give us via email or other direct contact from you. We will not
              sell or rent this information to anyone. We will use your
              information to respond to you, regarding the reason you contacted
              us. We will not share your information with any third party
              outside of our organization, other than as necessary to fulfill
              your request, e.g. to ship an order. Unless you ask us not to, we
              may contact you via email in the future to tell you about
              specials, new products or services, or changes to this privacy
              policy.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>
              Your Access to and Control Over Information
            </p>
            <p className={classes["label"]}>
              You may opt out of any future contacts from us at any time. You
              can do the following at any time by contacting us via the email
              address or phone number given on our website:
              <span>
                <div />
                See what data we have about you, if any.
              </span>
              <span>
                <div />
                Change/correct any data we have about you.
              </span>
              <span>
                <div />
                Have us delete any data we have about you.
              </span>
              <span>
                <div />
                Express any concern you have about our use of your data.
              </span>
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>
              Registration
            </p>
            <p className={classes["label"]}>
              In order to use this website, a user must first complete the
              registration form. During registration a user is required to give
              certain information (such as name and email address). This
              information is used to contact you about the products/services on
              our site in which you have expressed interest. At your option, you
              may also provide demographic information (such as gender or age)
              about yourself, but it is not required. Orders
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>Orders</p>
            <p className={classes["label"]}>
              We request information from you on our order form. To buy from us,
              you must provide contact information (like name and shipping
              address) and financial information (like credit card number,
              expiration date). This information is used for billing purposes
              and to fill your orders. If we have trouble processing an order,
              we'll use this information to contact you.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>Sharing</p>
            <p className={classes["label"]}>
              We share aggregated demographic information with our partners and
              advertisers. This is not linked to any personal information that
              can identify any individual person. We use an outside shipping
              company to ship orders, and a credit card processing company to
              bill users for goods and services. These companies do not retain,
              share, store or use personally identifiable information for any
              secondary purposes beyond filling your order.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>
              Surveys & Contests
            </p>
            <p className={classes["label"]}>
              From time-to-time our site requests information via surveys or
              contests. Participation in these surveys or contests is completely
              voluntary and you may choose whether or not to participate and
              therefore disclose this information. Information requested may
              include contact information (such as name and shipping address),
              and demographic information (such as zip code, age level). Contact
              information will be used to notify the winners and award prizes.
              Survey information will be used for purposes of monitoring or
              improving the use and satisfaction of this site.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>Links</p>
            <p className={classes["label"]}>
              This website contains links to other sites. Please be aware that
              we are not responsible for the content or privacy practices of
              such other sites. We encourage our users to be aware when they
              leave our site and to read the privacy statements of any other
              site that collects personally identifiable information.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>Cookies</p>
            <p className={classes["label"]}>
              We use "cookies" on this site. A cookie is a piece of data stored
              on a site visitor's hard drive to help us improve your access to
              our site and identify repeat visitors to our site. For instance,
              when we use a cookie to identify you, you would not have to log in
              a password more than once, thereby saving time while on our site.
              Cookies can also enable us to track and target the interests of
              our users to enhance the experience on our site. Usage of a cookie
              is in no way linked to any personally identifiable information on
              our site. Some of our business partners may use cookies on our
              site (for example, advertisers). However, we have no access to or
              control over these cookies.
            </p>
            <p className={`${classes["label"]} ${classes["title"]}`}>
              Security
            </p>
            <p className={classes["label"]}>
              We take precautions to protect your information. When you submit
              sensitive information via the website, your information is
              protected both online and offline. Wherever we collect sensitive
              information (such as credit card data), that information is
              encrypted and transmitted to us in a secure way. You can verify
              this by looking for a lock icon in the address bar and looking for
              "https" at the beginning of the address of the Web page. While we
              use encryption to protect sensitive information transmitted
              online, we also protect your information offline. Only employees
              who need the information to perform a specific job (for example,
              billing or customer service) are granted access to personally
              identifiable information. The computers/servers in which we store
              personally identifiable information are kept in a secure
              environment.
            </p>
            <p className={classes["label"]}>
              If you feel that we are not abiding by this privacy policy, you
              should contact us immediately via via email @ support@en2.tech
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default TermsOfServicePage;
