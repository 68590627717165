import axios from "axios";

const sendMail = async (
  fullname: string,
  services: Array<string>,
  heardFrom: string,
  email: string,
  phone: string,
  budget: string,
  projectBrief: string
) => {
  const form = new FormData();
  try {
    form.append(
      "from",
      "EN2.Tech <en2.tech@cyboticx.com>"
    );
    form.append("to", process.env.REACT_APP_CONTACT_FORM_EMAIL!);
    form.append("subject", "EN2 Contact Form");
    form.append(
      "text",
      `Full name: ${fullname}\nEmail: ${email}\nPhone: ${phone}\nHeard about EN2 from: ${heardFrom}\nProject Brief: ${projectBrief}\nServices: ${services.join(
        ", "
      )}\nBudget: ${budget}`
    );

    const endpoint =
      "https://api.mailgun.net/v3/mg.cyboticx.com/messages";

    await axios.post(endpoint, form, {
      auth: {
        username: "api",
        password: process.env.REACT_APP_MAILGUN_API_KEY!,
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  } catch (error) {
    return false;
  }
};

export default sendMail;
