import React from "react";
import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import AWSCertifiedPage from "./pages/AWSCertifiedPage";
import CloudAdministrationPage from "./pages/CloudAdministrationPage";
import ContactPage from "./pages/ContactPage";
import CustomSoftwareDevelopmentPage from "./pages/CustomSoftwareDevelopmentPage";
import HomePage from "./pages/HomePage";
import ManagedServiceProviderPage from "./pages/ManagedServiceProviderPage";
import MobileAppDevelopmentPage from "./pages/MobileAppDevelopmentPage";
import OnSiteServicePage from "./pages/OnSiteServicePage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<HomePage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/terms-of-service" element={<TermsOfServicePage />} />
      {/* <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> */}
      <Route
        path="/services/custom-software-development"
        element={<CustomSoftwareDevelopmentPage />}
      />
      <Route
        path="/services/mobile-app-development"
        element={<MobileAppDevelopmentPage />}
      />
      <Route
        path="/services/cloud-administration"
        element={<CloudAdministrationPage />}
      />
      <Route path="/services/aws-certified" element={<AWSCertifiedPage />} />
      <Route
        path="/services/managed-service-provider"
        element={<ManagedServiceProviderPage />}
      />
      <Route path="/services/on-site-service" element={<OnSiteServicePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </ReactRoutes>
  );
};

export default Routes;
