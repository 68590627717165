import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import useKeyPress from "../../hooks/useKeyPress";
import R from "../../res";
import classes from "./index.module.scss";

const CustomSoftwareDevelopmentPage = () => {
  const navigate = useNavigate();
  const escapeKeyPressed = useKeyPress("Escape");

  React.useEffect(() => {
    if (escapeKeyPressed) {
      navigate(-1);
    }
  }, [escapeKeyPressed]);

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
          translateY: 30,
          // scale: 3,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          // scale: 1,
          transition: {
            duration: 0.4,
          },
        }}
        exit={{
          opacity: 0,
          translateY: 30,
          // scale: 3,
          transition: {
            duration: 0.4,
          },
        }}
        className={classes["container"]}
      >
        <div className={classes["content-wrapper"]}>
          <img
            src={R.images.default.modalBackground}
            alt="background"
            className={classes["background"]}
          />
          <button
            className={classes["back-button"]}
            onClick={() => {
              navigate(-1);
            }}
          >
            <img src={R.images.default.modalBackIcon} alt="back-button" />
          </button>
          <div className={classes["content"]}>
            <div className={classes["image-wrapper"]}>
              <img
                src={R.images.default.shape}
                alt="shape"
                className={classes["image"]}
              />
              <img
                src={R.images.default.customSoftwareDevelopmentIcon}
                alt="Custom Software Development"
                className={classes["center-icon"]}
              />
            </div>
            <p className={classes["main-title"]}>Custom Software Development</p>
            <p className={classes["main-label"]}>
              We offer a wide range of solutions ranging from small touch ups,
              remodels, <br /> all the way to full business software solutions.
            </p>

            {Array(2)
              .fill("ee")
              .map((_, index) => (
                <div key={index}>
                  <p className={classes["label"]}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    hendrerit metus ipsum, nec bibendum massa dapibus id. Orci
                    varius natoque penatibus et magnis dis parturient montes,
                    nascetur ridiculus mus. Sed nunc turpis, feugiat quis elit
                    in, iaculis iaculis lorem. Vestibulum ante ipsum primis in
                    faucibus orci luctus et ultrices posuere cubilia curae;
                    Fusce tempus accumsan sagittis. Donec et scelerisque lorem.
                    In non laoreet lectus. Interdum et malesuada fames ac ante
                    ipsum primis in faucibus. Fusce ut nulla sed risus porta
                    lobortis at pharetra libero. Donec at diam ac ipsum pretium
                    condimentum. Vivamus convallis urna vel iaculis volutpat.
                    Nunc ac vestibulum purus, a convallis massa. Nulla venenatis
                    tellus id neque cursus, id iaculis quam vulputate.
                  </p>

                  <p className={classes["label"]}>
                    Fusce molestie ullamcorper augue sed vulputate. Nam
                    imperdiet augue sed sem semper, quis varius nisl rutrum.
                    Curabitur at mi ut odio porttitor tincidunt quis sit amet
                    mi. Fusce rutrum quam consectetur, feugiat purus et, egestas
                    nisl. Nam at placerat dolor. Vivamus ut erat eu tortor
                    consequat congue quis non purus. Ut ultricies lorem et orci
                    pellentesque laoreet. Proin porta volutpat diam a tempor.
                    Sed risus turpis, pellentesque sed rutrum vitae, scelerisque
                    accumsan elit. Curabitur auctor commodo congue.
                  </p>
                </div>
              ))}

            <button
              className={classes["button"]}
              onClick={() => navigate("/contact")}
            >
              <p>Contact Us</p>
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CustomSoftwareDevelopmentPage;
