import { Variants } from "framer-motion";
export const ease = [0.6, 0.05, -0.01, 0.9];

export const fadeInUpVariants: Variants = {
  fadeIn: (delay: number) => ({
    translateY: 0,
    transition: {
      duration: 0.7,
      delay: delay,
      ease,
    },
  }),
  fadeOut: {
    translateY: "110%",
    transition: {
      duration: 0.7,
    },
  },
};

export const fadeInVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 1,
    transition: {
      duration: 0.7,
      delay: delay,
      ease: "easeInOut",
    },
  }),
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const scaleInUpVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 1,
    scale: 1,
    rotate: "0deg",
    transition: {
      duration: 0.6,
      delay: delay,
    },
  }),
  fadeOut: {
    opacity: 0,
    scale: 0,
    rotate: "90deg",
    transition: {
      duration: 0.7,
    },
  },
};

export const scaleInVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.6,
      delay: delay,
    },
  }),
  fadeOut: {
    opacity: 0,
    scale: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const backgroundFadeInVariants: Variants = {
  fadeIn: (delay: number) => ({
    opacity: 0.2,
    transition: {
      duration: 0.6,
      delay: delay,
    },
  }),
  fadeOut: {
    opacity: 0,
    transition: {
      duration: 0.7,
    },
  },
};

export const viewVariants: Variants = {
  scaleUp: {
    scale: 2,
    opacity: 0,
    transition: {
      duration: 0.7,
      opacity: {
        duration: 0.5,
      },
    },
  },
  scaleDown: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.7,
      opacity: {
        duration: 0.5,
      },
    },
  },
};

export const blinkVariants: Variants = {
  show: (delay: number) => ({
    opacity: 1,
    scale: 1,
    rotate: "0deg",
    transition: {
      duration: 0.6,
      delay: delay,
    },
  }),
  hide: (delay: number) => ({
    opacity: 0,
    scale: 0,
    rotate: "90deg",
    transition: {
      duration: 0.7,
      delay: delay,
    },
  }),
};
